import { situationEvalueesTypes } from '@/views/Patients/Dossier/Constants'
import { Component, Vue } from 'vue-property-decorator'
import Alert from '@/components/shared/Alert.vue'
import { ErrorService } from '@/services/error.service'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Subscription } from 'rxjs'
import { PediatryService } from '@/services/patient-pediatry-service'
import { PediatrySituationModel } from '@/models/podiatry-observation-model'
import Commons from '@/components/shared/Helpers/commons'
import AppointmentSelector from '@/components/shared/AppointmentSelector/AppointmentSelector.vue'
import { AppointmentForConsultationResponseModel } from '@/models/agenda-model'
import { AgendaService } from '@/services/agenda-service'
import Confirm from '@/components/shared/Confirm/confirm.vue'

@Component({
  components: {
    Alert,
    Confirm,
    ValidationObserver,
    ValidationProvider,
    AppointmentSelector
  }
})
export default class EvaluationSituation extends Vue {
  private pediatryService = PediatryService.getInstance()
  private agendaService = AgendaService.getInstance()
  private subscription!: Subscription

  public situationsEvalueeTypes = situationEvalueesTypes
  public situation: PediatrySituationModel = {}
  public isEdit = false
  public selection: number[] = []
  public errorMessages: string[] = []
  public situationErrors = ''
  public isSaving = false
  public dossierId!: string
  public appointments: AppointmentForConsultationResponseModel[] = []
  private readyAppointments = false

  public showConfirm = false
  public routeNextCallback = null as any
  public redirectPath = null as string | null
  public hasSaved = false

  beforeRouteLeave (to, from, next) {
    if (!this.hasSaved && !this.isReadOnly) {
      if (this.redirectPath == null) {
        this.redirectPath = to
      }
      this.showConfirm = true
      this.routeNextCallback = next
      return false
    } else {
      next()
    }
  }

  public beforeUnloadHandler (e) {
    if (!this.isReadOnly) {
      e.returnValue = 'Vos modifications ne sont peut-être pas enregistrées, voulez-vous vraiment quitter cette page ?'
    }
    return ''
  }

  public confirmCallback (value: boolean) {
    this.showConfirm = false
    if (value && this.routeNextCallback) {
      this.routeNextCallback()
    }
  }

  public beforeDestroy () {
    window.removeEventListener('beforeunload', this.beforeUnloadHandler)
  }

  public get isReadOnly () {
    return this.situation.isLinkedToInvoice
  }

  public mounted () {
    window.addEventListener('beforeunload', this.beforeUnloadHandler)
    this.readyAppointments = false
    this.dossierId = this.$route.params.dossierId
    const situationId = this.$route.params.id
    this.subscription = this.pediatryService.pediatrySituationSelected$.subscribe(selectedSituation => {
      this.situation = selectedSituation
      this.selection = [...this.situation.situationEvalueeTypeIds ?? []]
      if (situationId === 'new' && this.dossierId) {
        this.situation.dossierId = this.dossierId
      }
      if (!this.situation.id && situationId !== 'new') {
        const path = `/patient`
        this.$router.push(path)
      }
      this.isEdit = !!selectedSituation.id
      this.getAllRelevantAppointments()
    })
  }

  public get getInfoText () {
    if (this.isEdit) {
      return "Une saisie d'heures sera automatiquement créée pour cette évaluation."
    }
    return "La saisie d'heures existante sera automatiquement adaptée pour cette évaluation."
  }

  public async save () {
    if (!this.selection.length) {
      this.situationErrors = 'La situation est manquante'
      return
    }
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    this.situationErrors = ''
    if (isValid) {
      this.isSaving = true
      this.situation.situationEvalueeTypeIds = this.selection
      const results = await this.pediatryService.AddPediatrySituation(this.dossierId, this.situation)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })
        .finally(() => {
          this.isSaving = false
        })
      if (results) {
        this.hasSaved = true
        this.redirectPath = null
        this.goToPediatryEvaluatoins()
      }
    } else {
      Commons.focusFirstComponentWithError(observer, this.$refs, 'Ref')
    }
  }

  public hideAlert () {
    this.errorMessages = []
  }

  public goBackButton () {
    this.redirectPath = null
    // act as if we saved, so that we bypass the confirm dialog
    this.hasSaved = true
    this.goToPediatryEvaluatoins()
  }

  public async goToPediatryEvaluatoins (forceReset = false) {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    observer.reset()
    this.errorMessages = []
    if (forceReset) {
      this.redirectPath = null
    }
    if (this.redirectPath == null) {
      this.$router.go(-1)
    } else {
      this.$router.push(this.redirectPath)
    }
  }

  private getAllRelevantAppointments () {
    this.agendaService.getAppointmentsForPediatriqueEvaluation(this.dossierId, this.situation?.id).then((appointments) => {
      this.appointments = appointments
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.errorMessages = res.errors
    }).finally(() => {
      this.readyAppointments = true
    })
  }

  public destroyed () {
    this.subscription.unsubscribe()
    this.pediatryService.updatePediatrySituationSelected({ date: Commons.GetTodayFormatted() })
  }
}
